import React from 'react';

const AppText = {
    // HeadingBig - for main headings
    HeadingBig: ({
        children,
        color,
        center = false,
        fontSize,
        fontWeight,
        maxLines,
        className = '',
        spans = []
    }) => {
        const style = {
            ...(color && { color }),
            ...(fontWeight && { fontWeight }),
            ...(fontSize && { fontSize }),
            ...(center && { textAlign: 'center' }),
            ...(maxLines && {
                WebkitLineClamp: maxLines,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            }),
        };

        if (spans.length > 0) {
            return (
                <div style={style} className={`text-large-title ${className}`}>
                    {children}
                    {spans.map((span, index) => (
                        <span key={index} style={span.style}>{span.text}</span>
                    ))}
                </div>
            );
        }

        return (
            <h1 style={style} className={`text-large-title ${className}`}>
                {children}
            </h1>
        );
    },

    // HeadingMedium - for secondary headings
    HeadingMedium: ({
        children,
        color,
        center = false,
        fontWeight,
        className = ''
    }) => {
        const style = {
            ...(color && { color }),
            ...(fontWeight && { fontWeight }),
            ...(center && { textAlign: 'center' }),
        };

        return (
            <h2 style={style} className={`text-title-1 ${className}`}>
                {children}
            </h2>
        );
    },

    // HeadingSmall - for section headings
    HeadingSmall: ({
        children,
        color,
        center = false,
        fontWeight,
        fontSize,
        maxLines,
        className = '',
        spans = [],
        padding,
    }) => {
        const style = {
            ...padding,
            ...(color && { color }),
            ...(fontWeight && { fontWeight }),
            ...(fontSize && { fontSize }),
            ...(center && { textAlign: 'center' }),
            ...(maxLines && {
                WebkitLineClamp: maxLines,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            }),
        };

        if (spans.length > 0) {
            return (
                <div style={style} className={`text-title-2 ${className}`}>
                    {children}
                    {spans.map((span, index) => (
                        <span key={index} style={span.style}>{span.text}</span>
                    ))}
                </div>
            );
        }

        return (
            <h3 style={style} className={`text-title-2 ${className}`}>
                {children}
            </h3>
        );
    },

    // SubHeading - for explanatory text
    SubHeading: ({
        children,
        color,
        center = false,
        fontWeight,
        fontSize,
        maxLines,
        className = '',
        spans = [],
        margins,
    }) => {
        const style = {
            ...margins,
            ...(color && { color }),
            ...(fontWeight && { fontWeight }),
            ...(fontSize && { fontSize }),
            ...(center && { textAlign: 'center' }),
            ...(maxLines && {
                WebkitLineClamp: maxLines,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            }),
        };

        return (
            <div style={style} className={`text-headline ${className}`}>
                {children}
                {spans.map((span, index) => (
                    <span key={index} style={span.style}>{span.text}</span>
                ))}
            </div>
        );
    },

    // BodyText - for general content
    BodyText: ({
        children,
        color,
        center = false,
        fontWeight,
        fontSize,
        maxLines,
        decoration,
        fontStyle,
        lineHeight,
        canCopy = false,
        className = '',
        spans = [],
        margins,
    }) => {
        const style = {
            ...margins,
            ...(color && { color }),
            ...(fontWeight && { fontWeight }),
            ...(lineHeight && { lineHeight }),
            ...(fontSize && { fontSize }),
            ...(center && { textAlign: 'center' }),
            ...(decoration && { textDecoration: decoration }),
            ...(fontStyle && { fontStyle }),
            ...(maxLines && {
                WebkitLineClamp: maxLines,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            }),
        };

        if (canCopy) {
            return (
                <div
                    style={style}
                    className={`text-body selectable ${className}`}
                    role="textbox"
                    tabIndex={0}
                >
                    {children}
                </div>
            );
        }

        return (
            <p style={style} className={`text-body ${className}`}>
                {children}
                {spans.map((span, index) => (
                    <span key={index} style={span.style}>{span.text}</span>
                ))}
            </p>
        );
    },

    // Caption - for supporting text
    Caption: ({
        children,
        color,
        center = false,
        fontWeight,
        lineHeight,
        maxLines,
        canCopy = false,
        className = ''
    }) => {
        const style = {
            ...(color && { color }),
            ...(fontWeight && { fontWeight }),
            ...(lineHeight && { lineHeight }),
            ...(center && { textAlign: 'center' }),
            ...(maxLines && {
                WebkitLineClamp: maxLines,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            }),
        };

        if (canCopy) {
            return (
                <div
                    style={style}
                    className={`text-callout selectable ${className}`}
                    role="textbox"
                    tabIndex={0}
                >
                    {children}
                </div>
            );
        }

        return (
            <small style={style} className={`text-callout ${className}`}>
                {children}
            </small>
        );
    },

    // Footnote - for additional information
    Footnote: ({
        children,
        color,
        center = false,
        fontWeight,
        maxLines,
        className = ''
    }) => (
        <small
            style={{
                ...(color && { color }),
                ...(fontWeight && { fontWeight }),
                ...(center && { textAlign: 'center' }),
                ...(maxLines && {
                    WebkitLineClamp: maxLines,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                }),
            }}
            className={`text-footnote ${className}`}
        >
            {children}
        </small>
    ),

    // ButtonText
    ButtonText: ({
        children,
        color,
        center = false,
        className = ''
    }) => (
        <span
            style={{
                ...(color && { color }),
                ...(center && { textAlign: 'center' }),
            }}
            className={`text-button ${className}`}
        >
            {children}
        </span>
    )
};

export default AppText;