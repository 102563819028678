import React from "react";
import { Menu } from "semantic-ui-react";
import { APP_WHITE_COLOR, CONTENT_CARD_PADDING, CONTENT_ELEMENT_PADDING, ELEMENT_PADDING } from "../../Theme/ThemeConstants";
import AppText from "../Texts/AppTexts";
import { VisitPageIcons } from "../Custom/ShareIcons";
import { BrowserView, MobileView } from "react-device-detect";
import { AppColumn } from "../Custom/Layouts";
import { SizedBox } from "../Custom/Sizes";


function AppFooter() {
  return (
    <div>
      <div style={{ marginTop: ELEMENT_PADDING, paddingBottom: CONTENT_ELEMENT_PADDING }}>
        <MobileView>
          <AppColumn>
            <VisitPageIcons style={{justifyContent: 'center'}} />
            <SizedBox height={CONTENT_ELEMENT_PADDING}/>
            <Menu borderless
            size='large'
            secondary
            style={{
              paddingLeft: CONTENT_ELEMENT_PADDING,
              paddingRight: CONTENT_ELEMENT_PADDING,
              width: '100%',
              borderRadius: '0px',
              justifyContent: 'center',
              marginBottom: '2rem'
            }}>
              <Menu.Menu  >
                <Menu.Item
                  as={"a"}
                  href={"/terms-of-use"}
                  name="terms-of-use"
                  content={(<AppText.Footnote color={APP_WHITE_COLOR}>Term of Use</AppText.Footnote>)}
                />
                <Menu.Item
                  name="2"
                  href={"/privacy"}
                  content={(<AppText.Footnote color={APP_WHITE_COLOR}>Privacy Policy</AppText.Footnote>)}
                />
              </Menu.Menu>
            </Menu>
          </AppColumn>
        </MobileView>
        <BrowserView>
          <Menu borderless
            size='large'
            secondary
            style={{
              paddingLeft: CONTENT_ELEMENT_PADDING,
              paddingRight: CONTENT_ELEMENT_PADDING,
              width: '100%',
              borderRadius: '0px',
              justifyContent: 'center',
              marginBottom: '2rem'
            }}>


            <VisitPageIcons style={{ marginRight: CONTENT_CARD_PADDING }} />

            <Menu.Menu  >
              <Menu.Item
                as={"a"}
                href={"https://thinkinteltech.com/term-of-use"}
                name="terms-of-use"
                content={(<AppText.Footnote color={APP_WHITE_COLOR}>Term of Use</AppText.Footnote>)}
              />
              <Menu.Item
                name="2"
                href={"https://thinkinteltech.com/privacy"}
                content={(<AppText.Footnote color={APP_WHITE_COLOR}>Privacy Policy</AppText.Footnote>)}
              />
            </Menu.Menu>
          </Menu></BrowserView>
      </div>
    </div>
  );
}

export default AppFooter;
