import React from "react";
import "./index.css";
import { Route, Routes } from "react-router-dom";

import AppFooter from "./Components/Footers/AppFooter";
import { INITIAL_PATH } from "./Constants/Paths";
import LandingPage from "./Pages/Landing/LandingPage";

function App() {

  return (
    <div>
      <Routes>
        <Route exact path={INITIAL_PATH} element={<LandingPage />} />
      </Routes>
      <div className="App-footer">
        <AppFooter />
      </div>
    </div>
  );
}

export default App;
