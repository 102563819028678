import React from 'react';

export const AppScreenshots = ({ svgPath }) => {
    return (
        <div
            style={{
                margin: '20px',
            }}
        >
            <div
                className="screenshot-container"
                style={{
                    position: 'relative',
                    height: '600px',
                    boxShadow: '0 10px 15px rgba(0, 0, 0, 0.5)',
                    borderRadius: '50px',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={svgPath}
                    alt="Screenshot"
                    style={{
                        height: '100%',
                        display: 'block',
                    }}
                />
            </div>
        </div>
    );
};
