import React from 'react';

const NoisePattern = () => (
  <svg
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.3,
      pointerEvents: 'none'
    }}
  >
    <defs>
      <filter id="noiseFilter">
        <feTurbulence
          type="fractalNoise"
          baseFrequency="0.8"
          numOctaves="3"
          stitchTiles="stitch"
          result="noise"
        />
      </filter>
    </defs>
    <rect
      width="100%"
      height="100%"
      filter="url(#noiseFilter)"
      style={{ width: '100%', height: '100%' }}
    />
  </svg>
);

const GradientNoiseBackground = ({ children }) => {
  return (
    <div>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(2deg, #6C0430, #3F2677`,
          zIndex: -2
        }}
      />

      <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: -1 }}>
        <NoisePattern />
      </div>

      <div className="min-h-screen">
        {children}
      </div>
    </div>
  );
};


export default GradientNoiseBackground;