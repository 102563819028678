import { Container, Grid, Icon, Image } from "semantic-ui-react";
import GradientNoiseBackground from "../../Components/Backgrounds/NoiceBackground";
import { AppColumn, AppRow } from "../../Components/Custom/Layouts";
import { SizedBox } from "../../Components/Custom/Sizes";
import AppText from "../../Components/Texts/AppTexts";
import { APP_PRIMARY_COLOR, APP_WHITE_COLOR, CONTENT_CARD_PADDING, CONTENT_CARD_PADDING_BIG, CONTENT_ELEMENT_PADDING, ELEMENT_PADDING } from "../../Theme/ThemeConstants";
import { APPSTORE, COLLECT_DETAILS_SCREENSHOT, GET_STARTED_SCREENSHOT, GOAL_DETAIL_SCREENSHOT, LOGO, SEE_GOALS_SCREENSHOT } from "../../Constants/Assets";
import { AppScreenshots } from "../../Components/Custom/Screenshots";
import { withOpacity } from "../../Utils/HelperFunctions";


const featuresData = [
    {
        icon: "joomla icon",
        title: 'Chat: Create Goal',
        description: "Create goals through chatting(text & voice) with AiM.",
    },
    {
        icon: 'bell outline icon',
        title: 'Reminders',
        description: "Receive periodic goal checkin reminders from AiM.",
    },
    {
        icon: "tasks icon",
        title: 'Progress',
        description: "Visualise your progress on a heat - map.",
    }
]

const LandingPage = () => {
    const screenshots = [
        GET_STARTED_SCREENSHOT,
        COLLECT_DETAILS_SCREENSHOT,
        GOAL_DETAIL_SCREENSHOT,
    ];


    return (<GradientNoiseBackground>
        <Container  >
            <AppColumn>
                <SizedBox height={CONTENT_ELEMENT_PADDING} />
                <Image
                    src={LOGO}
                    alt="Logo"
                    size="tiny"
                    centered
                />
                <AppText.HeadingBig color={APP_WHITE_COLOR} center fontWeight={800} fontSize={60}>Achieve any goal with an AI</AppText.HeadingBig>
                <SizedBox height={ELEMENT_PADDING} />
                <AppText.SubHeading color={APP_WHITE_COLOR} center >...get daily, weekly or monthly reminders from ai that motivates you to accomplish your goals</AppText.SubHeading>
                <SizedBox height={CONTENT_CARD_PADDING} />
                <Image
                    href={'https://apps.apple.com/us/app/aim-goalkit/id6736947191'}
                    src={APPSTORE}
                    centered
                    alt="Logo"
                    style={{
                        height: '4rem',
                        width: '12rem'
                    }}
                />
                <SizedBox height={CONTENT_CARD_PADDING} />
                <div
                    style={{
                        display: 'flex',
                        gap: '20px',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {screenshots.map((path, index) => (
                        <AppScreenshots key={index} svgPath={path} />
                    ))}

                </div>
                <SizedBox height={CONTENT_CARD_PADDING} />
                <AppText.HeadingMedium color={APP_PRIMARY_COLOR} center fontWeight={800}>Key Features</AppText.HeadingMedium>
                <SizedBox height={CONTENT_ELEMENT_PADDING} />
                <Grid
                    centered
                    stackable
                    columns={4}
                >
                    {featuresData.map((feature) => (
                        <Grid.Column>
                            <Feature
                                icon={feature.icon}
                                title={feature.title}
                                description={feature.description}
                            />
                        </Grid.Column>
                    ))}
                </Grid>
                <SizedBox height={CONTENT_CARD_PADDING_BIG} />
                <Image
                    href={'https://apps.apple.com/us/app/aim-goalkit/id6736947191'}
                    src={APPSTORE}
                    centered
                    alt="Logo"
                    style={{
                        height: '4rem',
                        width: '12rem'
                    }}
                />
                <SizedBox height={CONTENT_CARD_PADDING} />
            </AppColumn>
        </Container>
    </GradientNoiseBackground>);
}


function Feature({ icon, title, description, }) {
    return (<div>

        <AppRow>
            <Icon name={icon} size='large' style={{ marginRight: ELEMENT_PADDING, marginTop: '3px', color: APP_WHITE_COLOR, }} />
            <AppText.SubHeading color={APP_WHITE_COLOR} fontWeight={800}>{title}</AppText.SubHeading>
        </AppRow>
        <SizedBox height={ELEMENT_PADDING} />
        <AppText.BodyText color={withOpacity(APP_WHITE_COLOR, '0.65')}>{description}</AppText.BodyText>

    </div>);
}

export default LandingPage;