

export const APP_PRIMARY_COLOR = '#E6BC51';
export const APP_SECONDARY_COLOR = '#6941C6';

export const APP_DARK_COLOR = "#151E23";
export const APP_WHITE_COLOR = "#FFFFFF";
export const APP_GREY_COLOR = "#8E9497";


export const CONTENT_CARD_PADDING = '4rem'
export const CONTENT_CARD_PADDING_BIG = '6rem'
export const CONTENT_ELEMENT_PADDING = '1.5rem';

export const ELEMENT_PADDING = '0.5rem';



export const BUTTON_HEIGHT = '50px';
export const INPUT_FIELD_HEIGHT = '50px';
