import React from "react";


export const SizedBox = ({ width, height, children, style }) => (
    <div
      style={{
        width: width || 'auto',
        height: height || 'auto',
        display: 'flex',
        ...style,
      }}
    >
      {children}
    </div>
  );