import React from "react";


export const AppColumn = ({ children, gap, style }) => (
    <div style={{
        display: 'flex', flexDirection: 'column', ...style,
        ...(gap && { gap }),
    }}>
        {children}
    </div>
);

export const AppRow = ({ children, style, gap }) => (
    <div style={{
        display: 'flex', flexDirection: 'row', ...style,
        ...(gap && { gap }),

    }}>
        {children}
    </div>
);


export const Expanded = ({ children }) => {
    return (
        <div style={{ flex: 1 }}>
            {children}
        </div>
    );
};


export const Spacer = () => {
    return <div style={{ flex: 1 }} />;
};