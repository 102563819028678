import React from "react";
import { Image } from "semantic-ui-react";
import { EMAIL_ICON, LINKEDIN_ICON, X_ICON } from "../../Constants/Assets";
import { CONTENT_CARD_PADDING, CONTENT_ELEMENT_PADDING } from "../../Theme/ThemeConstants";

export const VisitPageIcons = ({ style }) => {
    return (
        <div style={{
            display: "flex",
            alignContent: 'start',
            marginTop: '10px',
            gap: CONTENT_ELEMENT_PADDING,
            ...style,
        }}>
            <a
                href={`https://www.linkedin.com/in/edemekong`}
                target="_blank"
            >
                <Image src={LINKEDIN_ICON} style={iconStyle} />
            </a>
            <a
                href={`https://x.com/aigoalkit`}
                target="_blank"
            >
                <Image src={X_ICON} style={iconStyle} />
            </a>
            <a
                href={`mailto:paul@thinkinteltech.com?subject=${encodeURIComponent("Hi PAUL")}&body=${encodeURIComponent(
                '')}`}
                target="_blank"
            >
                <Image src={EMAIL_ICON} style={iconStyle} />
            </a>
        </div>
    );
};

const iconStyle = {
    color: "white", 
    cursor: "pointer",
    display: "flex",
    width: '20px',
  };
  