const ASSETS_PATH = `/assets`;

//Logos
export const FACEBOOK_ICON = `${ASSETS_PATH}/icons/facebook.png`;
export const LINKEDIN_ICON = `${ASSETS_PATH}/icons/linkedin.png`;
export const X_ICON = `${ASSETS_PATH}/icons/twitter.png`;
export const EMAIL_ICON = `${ASSETS_PATH}/icons/email.png`;

// IMAGES
export const LOGO = `${ASSETS_PATH}/logo.png`;
export const APPSTORE = `${ASSETS_PATH}/appstore.png`;
export const PLAY_STORE = `${ASSETS_PATH}/playstore.png`;


// SCREENSHOTS
export const GET_STARTED_SCREENSHOT = `${ASSETS_PATH}/screenshots/get_started.svg`;
export const COLLECT_DETAILS_SCREENSHOT = `${ASSETS_PATH}/screenshots/collect_detail.svg`;
export const GOAL_DETAIL_SCREENSHOT = `${ASSETS_PATH}/screenshots/goal_detail.svg`;
export const SEE_GOALS_SCREENSHOT = `${ASSETS_PATH}/screenshots/see_goals.svg`;